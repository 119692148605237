
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import ProductionStage from "@/components/Manufacturing/ProductionStage.vue";

export default defineComponent({
  name: "OriginalOrders",
  components: {
    ProductionStage,
    Card,
  },
  computed: {
    ...mapState(["shipControl"]),
    ...mapGetters({}),
  },
  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      filename: "SOH",
      productionStage: [],
      pageTitle: "Sales Orders",
      dynamicColumns: [
        { field: "PO_NUMBER", header: "Cust PO", input: true },
        { field: "so_id", header: "Sales Order", input: true },
      ],
    };
  },

  methods: {
    ...mapActions({}),
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
