import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductionStage = _resolveComponent("ProductionStage")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_ProductionStage, {
            isResultView: _ctx.isResultView,
            showSelectionCheckbox: true,
            onLastUpdated: _ctx.isDataUpdated,
            filename: _ctx.filename,
            "production-stage": _ctx.productionStage,
            "dynamic-columns": _ctx.dynamicColumns,
            "page-title": "Original Orders",
            "show-copy-button": false,
            "copy-button-text": "Copy",
            "dialog-read-only": true,
            "show-delete-button": false,
            "command-name": "CW.MAN.ORIG"
          }, null, 8, ["isResultView", "onLastUpdated", "filename", "production-stage", "dynamic-columns"])
        ]),
        _: 1
      })
    ])
  ]))
}